import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { Link } from "gatsby"

const thankyou = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marsandi | Thank You</title>
        <link rel="canonical" href="https://marsandi.com/thankyou" />
        <link rel="icon" href="https://marsandi.com/Marsandi-Logo-color.png" />
        <meta
          name="Description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        ​<meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" contact="@devmarsandi"></meta>
        <meta name="twitter:creator" content="@devmarsandi"></meta>
        <meta
          name="twitter:title"
          content="Bring Your Business To The New Era"
        ></meta>
        <meta
          name="twitter:description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        <meta
          name="twitter:image"
          content="https://marsandi.com/Marsandi-Logo-color.png"
        ></meta>
        ​
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Marsandi" />
        <meta
          property="og:title"
          content="Bring Your Business To The New Era"
        ></meta>
        <meta property="og:url" content="https://marsandi.com"></meta>
        <meta
          property="og:description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        <meta
          property="og:image"
          content="https://marsandi.com/Marsandi-Logo-color.png"
        ></meta>
        <meta property="og:image:alt" content="Logo"></meta>
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="text-customgray">
        <div className="py-20 px-2 text-customgray h-full lg:h-4/6 text-center grid justify-items-center align-middle">
          <div className="w-full lg:w-5/12">
            <h1 className="text-3xl mb-10 lg:text-4xl text-customgray">
              Submitted!
            </h1>
            <h1 className="text-3xl lg:text-4xl text-customgray">
              We'll be reaching out soon
            </h1>
            <h3 className="text-xl mt-5 text-customgray">
              Thanks for your submission. One of our team members will be
              reaching out soon.
            </h3>
            <Link
              to="/"
              className="mx-2 md:mx-0 inline-block text-md px-4 py-4 leading-none rounded text-white bg-customblue mt-5"
            >
              Home
            </Link>
          </div>
        </div>
        <div className="bg-customdarkblue grid justify-items-center align-middle">
          <img
            src="/banner-img-1.png"
            alt="Banner"
            width="1920"
            height="auto"
          />
        </div>
        <Banner />
      </section>
    </Layout>
  )
}

export default thankyou
